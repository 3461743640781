<template>
  <CRow v-if="platformPermissionsLoaded && (checkPermission('loyalty.moments.edittemplate') || (checkPermission('loyalty.moments.editcustomtemplate') && moment.custom_moment))">
    <CCol cols="12" lg="12">
      <CCard class="mb-0">
        <CCardHeader class="pb-0">
          <CRow>
            <CCol cols="4" xl="4" class="pt-0 pb-0 text-left">
              <span>{{$t('loyalty.Moment')}}{{momentLoading === false ? ': ' + moment.name : ''}}</span>
              <div v-if="moment.is_available_for_external_api" class="moment_tag">
                <span>{{$t('loyalty.Tag_for_external_api')}}: <b>{{moment.tag}}</b></span>
              </div>              
            </CCol>
            <CCol v-if="momentLoading === false" cols="8" xl="8" class="pt-0 pb-0 text-xl-right">
              <div class="mt-1 mt-xl-0 d-flex justify-content-lg-end">
                <b-switch v-model="moment.is_active" class="m-0 mr-2" size="is-small" @input="filterStoreData()">{{$t('common.Active')}}</b-switch>
                <b-switch v-if="platformRolesLoaded && checkRole('super_admin')" v-model="moment.is_globally_available" class="m-0 mr-2" size="is-small" @input="filterStoreData()">{{$t('common.Globally_available')}}</b-switch>
                <CButton v-if="checkPermission('core.emailtemplates') && emailEditorReady && templates.length > 0" 
                         @click="selectTemplateView = true" 
                         color="secondary" 
                         class="btn_small">
                  <span><i class="fa-solid fa-envelope mr-1"></i>{{$t('common.Load_email_template')}}</span>
                </CButton>
                <CButton v-if="checkPermission('core.emailtemplates') && emailEditorReady && moment.json"
                         @click="openSidebarRight('save_email_template', { template_type: 'moment-email', template_source_id_external:  moment.loyalty_moment_template_id_external, product_tag: 'loyalty' })" 
                         color="secondary"
                         class="btn_small">
                  <span><i class="fa-solid fa-envelope-circle-check mr-2"></i>{{$t('common.Save_as_email_template')}}</span>
                </CButton>
                <CButton v-if="emailEditorReady" @click="updateMomentTemplate(moment.loyalty_moment_template_id_external, moment);" color="primary" class="m-0 btn_small">
                  <span><i class="fas fa-check mr-1"/>{{$t('loyalty.Update_moment_template')}}</span>
                </CButton>                
              </div>             
            </CCol>            
          </CRow>
        </CCardHeader>
        <CCardBody class="event moment">
          <CRow v-if="momentLoading === true">
            <CCol cols="12" lg="12">
              <loadingSpinner mode="auto" :content="$t('common.Loading')"/>
            </CCol>
          </CRow>
          <CRow v-if="momentLoading === false">
            <CCol cols="12" lg="12" class="pt-0 pb-0">
              <CRow class="p-0">
                <CCol cols="12" lg="12" class="pt-0 pb-0">
  
                  <transition name="slide-big">
                    <CRow v-if="selectTemplateView">
                      <CCol cols="12" lg="12" class="pt-0 pb-0">
                        <span>{{$t('common.Pick_mail_template')}}</span>
                      </CCol>
                      <CCol cols="3" lg="3">
                        <navigationCard @click.native="setEmailTemplate(moment.tag, moment.json)" path="" :cardTitle="$t('common.Current_template')" :cardDescription="$t('common.Keep_using_current_template')" :cardIcon="null" class="h-100"/>
                      </CCol>                                     
                      <CCol v-for="template in templates" v-bind:key="template.platform_event_email_template_id_external" cols="3" lg="3">
                        <navigationCard @click.native="setEmailTemplate(moment.tag, template.json)" path="" :cardTitle="template.name" :cardDescription="template.description" :cardIcon="null" class="h-100"/>
                      </CCol>
                    </CRow>
                  </transition>                               
                         
                  <CRow class="p-0">
                    <CCol cols="12" lg="12" class="pt-0">
                      <b-collapse class="panel show" :open="true">
                        <div slot="trigger" slot-scope="props" class="panel-heading" role="button">
                          <strong>{{$t('communications.email')}}</strong>
                          <a>{{props.open ? $t('close') : $t('open')}}</a>
                        </div>
                        <div class="panel-block p-0">
                          <CRow class="w-100 m-0">
                            <CCol cols="12" lg="12" class="pb-0">
                              <CInput type="text" :label="$t('common.Subject')" class="mb-0" v-model="moment.subject" required was-validated/>
                            </CCol>
                          </CRow>
                          <CRow class="w-100 m-0">
                            <CCol cols="12" lg="12" class="pb-0">
                              <label class="mt-0 mb-2">{{$t('communications.email_content')}}</label>
                              <div v-if="moment.email_content_tags" class="mb-1">
                                <span>{{ $t('communications.available_tags') }} {{moment.email_content_tags}}</span>
                              </div>
                            </CCol>
                          </CRow>
                          <CRow class="w-100 m-0">
                            <CCol cols="12" lg="12">
                              <EmailEditor :ref="'emailEditor_' + moment.tag"
                                           :appearance="emailEditorConfig.appearance"
                                           :min-height="emailEditorConfig.minHeight"
                                           :project-id="emailEditorConfig.projectId"
                                           :locale="emailEditorConfig.locale"
                                           :tools="emailEditorConfig.tools"
                                           :options="emailEditorConfig.options"
                                           v-on:ready="editorReady(moment.tag)">
                              </EmailEditor>
                            </CCol>
                          </CRow>               
                        </div>
                      </b-collapse>
                    </CCol>
                  </CRow>

                  <CRow>
                    <CCol cols="12" xl="12" lg="12" md="12" sm="12" class="pt-0">
                      <b-collapse class="panel show" :open="true">
                        <div slot="trigger" slot-scope="props" class="panel-heading" role="button">
                          <strong>{{$t('communications.points')}}</strong>
                          <a>{{props.open ? $t('close') : $t('open')}}</a>
                        </div>
                        <div class="panel-block p-0">
                          <CRow class="w-100 m-0">                            
                            <CCol cols="12" xl="12" lg="12" md="12" sm="12">
                              <MomentPoints :platformPermissions="platformPermissions" parent="moment" :points="moment.points" :boxed="false" :editable="true"/>
                            </CCol>
                          </CRow>
                        </div>
                      </b-collapse>
                    </CCol>
                  </CRow>

                  <CRow>
                    <CCol cols="12" xl="12" lg="12" md="12" sm="12" class="pt-0 pb-0">
                      <b-collapse class="panel show" :open="true">
                        <div slot="trigger" slot-scope="props" class="panel-heading" role="button">
                          <strong>{{$t('loyalty.Moment_template_tags')}}</strong>
                          <a>{{props.open ? $t('close') : $t('open')}}</a>
                        </div>
                        <div class="panel-block p-0">
                          <CRow class="w-100 m-0">                          
                            <CCol cols="12" xl="12" lg="12" md="12" sm="12">
                              <multiselect class="data_table"
                                           v-model="moment.loyalty_moment_tags"
                                           :options="momentTags"
                                           :multiple="true"
                                           track-by="loyalty_moment_template_tag_id_external" 
                                           label="tag"
                                           :taggable="true"
                                           @tag="addLoyaltyMomentTag">
                                  <span slot="noResult">{{ $t('common.no_types_found') }}</span>
                                </multiselect>                            
                            </CCol>
                          </CRow>
                        </div>
                      </b-collapse>
                    </CCol>
                  </CRow>                  

                  <!-- <CRow>
                    <CCol cols="12" lg="12" class="pt-0">
                      <b-collapse class="panel show" :open="true">
                        <div slot="trigger" slot-scope="props" class="panel-heading" role="button">
                          <strong>{{$t('communications.push')}}</strong>
                          <a>{{props.open ? $t('close') : $t('open')}}</a>
                        </div>
                        <div class="panel-block p-0">
                          <CRow class="w-100 m-0">
                            <CCol cols="4" lg="4">
                              <CInput @input="countdown()" type="text" maxlength="20" :label="$t('communications.Push_title')" class="mt-0 mb-1" v-model="moment.push_title"></CInput>
                              <span class="countdown" v-if="moment.remaining_push_title_length > 0">{{moment.remaining_push_title_length}} {{$t('common.characters_remaining')}}</span>
                            </CCol>
                            <CCol cols="8" lg="8">
                              <CInput @input="countdown()" type="text" maxlength="60" :label="$t('communications.Push_message')" class="mt-0 mb-1" v-model="moment.push_message"/>
                              <span class="countdown" v-if="moment.remaining_push_message_length > 0">{{moment.remaining_push_message_length}} {{$t('common.characters_remaining')}}</span>
                            </CCol>                              
                          </CRow>
                        </div>
                      </b-collapse>
                    </CCol>
                  </CRow> -->
                </CCol>
              </CRow>
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>           
    </CCol>
  </CRow>
  <noPermission v-else-if="platformPermissionsLoaded" trigger="permission"/>
</template>

<script>
import axios from 'axios'
import Multiselect from 'vue-multiselect';
import { EmailEditor } from 'vue-email-editor';
import { v4 as uuidv4 } from 'uuid';

import loadingSpinner from '@/components/common/loadingSpinner.vue';
import noPermission from '@/components/common/noPermission.vue';
import navigationCard from '@/components/common/navigationCard';

import MomentPoints from '@/components/core/MomentPoints.vue';

export default {
  name: 'Moment',
  components: {
    Multiselect,
    EmailEditor,
    loadingSpinner,
    noPermission,
    navigationCard,
    MomentPoints
  },
  watch: { 
    '$route.params.id': {
      handler() {
        this.momentTemplateIdExternal = this.$route.params.id;
        // Get the template details
        this.getMomentTemplateDetails(this.momentTemplateIdExternal);
        // Get the template tags
        this.getMomentTemplateTags();
      },
      deep: true,
      immediate: true
    }
  },  
  data() {
    return {
      momentTemplateIdExternal: null,
      platformPermissions: [],
      platformPermissionsLoaded: false,
      platformRoles: [],
      platformRolesLoaded: false,
      emailEditorInstructionsRead: false,
      apiBaseUrl: null,
      clientToken: null,
      moment: {},
      momentTags: [],
      momentLoading: false,
      momentLoaded: false,
      momentUpdatePath: null,
      newMomentPoints: null,
      templates: [],      
      selectTemplateView: false,
      emailEditorReady: false,
      emailEditorConfig: {
        minHeight: '700px',
        locale: 'en',
        projectId: 50935,
        tools: {
          image: { enabled: true } // Disable image tool
        },
        options: {
          mergeTags: {
            name: {
              name: "Employee Name",
              value: "{$name}"
            },
            ceo_name: {
              name: "Email Signatory",
              value: "{$email_signatory}"
            },            
            company: {
              name: "Platform Name",
              value: "{$platform_name}"
            },
            points: {
              name: "Points",
              value: "{$points}"
            },                        
            compliment: {
              name: "Compliment",
              value: "{$compliment}"
            },            
            copyright: {
              name: "Copyright",
              value: "{$copyright}"
            }                          
          },          
          specialLinks: [
            {
              name: 'Frequently used',
              specialLinks: [
                {
                  name: 'Link to Dashboard',
                  href: '{$dashboard_url}',
                  target: '_blank',
                },
                {
                  name: 'Link to Experience',
                  href: '{$app_url}',
                  target: '_blank',
                }
              ]
            }
          ]
        },
        appearance: {
          theme: 'light',
          panels: {
            tools: {
              dock: 'right',
            }
          }
        }              
      }        
    }
  },
  methods: {
    updateMomentTemplate(momentTemplateIdExternal) {
      let params = {};
      params.momentData = this.moment;
      if(!this.moment.json) params.momentData.json = null;
      if(!this.moment.html) params.momentData.html = null;      
      if(this.newMomentPoints) params.momentData.points = parseInt(this.newMomentPoints);
      (this.moment.is_active === true) ? params.momentData.active = 1 : params.momentData.active = 0;
      (this.moment.is_globally_available === true) ? params.momentData.globally_available = 1 : params.momentData.globally_available = 0;
      

      if(this.$refs !== undefined && this.$refs['emailEditor_' + this.moment.tag] !== undefined ) {
        this.$refs['emailEditor_' + this.moment.tag].editor.saveDesign((design) => {
          params.momentData.json = JSON.stringify(design);
        });

        this.$refs['emailEditor_' + this.moment.tag].editor.exportHtml((data) => {
          params.momentData.html = data.html;
        })
      }

      // Set 500ms timeout to make sure that the JSON and HTML data is added to the params
      setTimeout(function() {
        axios.put(process.env.VUE_APP_API_URL + '/v1/' + this.momentUpdatePath + '/' + momentTemplateIdExternal, params)
        .then(res => {
          this.$buefy.toast.open({ message: this.$t('loyalty.Moment_template_updated'), type: 'is-success', duration: 2000 });
          // Get the Moment template details
          this.getMomentTemplateDetails(momentTemplateIdExternal);
          // Update the preview image
          if(params.momentData.html) {
            axios.put(process.env.VUE_APP_API_URL + '/v1/' + this.momentUpdatePath + '/' + momentTemplateIdExternal + '/preview', params)
            .catch(err => {
              console.error(err);
              this.$buefy.toast.open({ message: this.$t('error_alert_text'), type: 'is-danger', duration: 2000 });
            });            
          }
          // Navigate back to Moments overview
          this.$router.push({path: '/loyalty/moments/templates'});           
        })
        .catch(err => {
          console.error(err);
          this.$buefy.toast.open({ message: this.$t('error_alert_text'), type: 'is-danger', duration: 2000 });
        });
      }.bind(this), 500);      
    },
    getMomentTemplateDetails(momentTemplateIdExternal) {
      // Start the loader
      if(!this.momentLoaded) this.momentLoading = true;
      // Get the event data
      axios.get(process.env.VUE_APP_API_URL + '/v1/loyalty/moments/template/' + momentTemplateIdExternal)
      .then(res => {
        this.moment = res.data.data;
        // Set the momentUpdatePath
        (this.moment.custom_moment === false) ? this.momentUpdatePath = "loyalty/moments/template" : this.momentUpdatePath = "loyalty/moments/template/custom";  
        // Set the max_length values
        this.moment.max_points_title_length = 50;
        this.moment.max_points_description_length = 200;
        this.moment.max_push_title_length = 20;
        this.moment.max_push_message_length = 60;        
        // Calculate the remaining_length values
        (this.moment.push_title !== null) ? this.moment.remaining_push_title_length = 20 - this.moment.push_title.length : this.moment.remaining_push_title_length = 20;          
        (this.moment.push_message !== null) ? this.moment.remaining_push_message_length = 60 - this.moment.push_message.length : this.moment.remaining_push_message_length = 60;
        (this.moment.points_title !== null) ? this.moment.remaining_points_title_length = 50 - this.moment.points_title.length : this.moment.remaining_points_title_length = 50;
        (this.moment.points_description !== null) ? this.moment.remaining_points_description_length = 200 - this.moment.points_description.length : this.moment.remaining_points_description_length = 200;
        // Stop the loader
        this.momentLoading = false;
        // Update the momentLoaded value
        this.momentLoaded = true;                      
        // Check if the Moment contains a valid JSON
        if(this.moment.json) {
          // Set the momentTag value
          let momentTag = this.moment.tag;
          // If so, set he mailDesign in the editor
          let mailDesign = JSON.parse(this.moment.json);
          
          setTimeout(function() {
            if(this.$refs['emailEditor_' + momentTag] && this.$refs['emailEditor_' + momentTag].editor) {
              this.$refs['emailEditor_' + momentTag].editor.loadDesign(mailDesign);
            }
          }.bind(this), 2000);
        }
      })
      .catch(err => {
        console.error(err); 
      }); 
    },
    getMomentTemplateTags() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/loyalty/moments/template/tags')
      .then(res => {      
        this.momentTags = res.data.data;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    addLoyaltyMomentTag(newTag) {
      const tag = { tag: newTag, loyalty_moment_template_tag_id_external: uuidv4() }
      // Add the new tag to the loyalty_moment_tags array of the moment
      this.moment.loyalty_moment_tags.push(tag);
      // Add the new tag to the momentTags array
      this.momentTags.push(tag);
    },
    editorReady(tag) {
      this.emailEditorReady = true;
            
      this.$refs['emailEditor_' + tag].editor.setBodyValues({
        backgroundColor: "#F6F6F6",
        textColor: '#4A4A4A',
        contentAlignment: 'center',
        links: {
          color: '#4A4A4A',
          underline: true
        }
      });
    },
    getEmailTemplates() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/email/templates/list')
      .then(res => {      
        this.templates = res.data.data;
      })
      .catch(err => {
        console.error(err); 
      });              
    },
    setEmailTemplate(momentTag, templateData) {
      // Update selectTemplateView value to close the view
      this.selectTemplateView = false;
        
      let mailDesign = JSON.parse(templateData);

      setTimeout(function(){          
        this.$refs['emailEditor_' + momentTag].editor.loadDesign(mailDesign);
      }.bind(this), 1000);
    },
    getPlatformRoles() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/platform/roles')
      .then(res => {      
        this.platformRoles = res.data.data;
        // Update the platformRolesLoaded alue
        this.platformRolesLoaded = true;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    checkRole(roleTag) {
      if(this.platformRoles.includes(roleTag)) {
        return true;
      } else{
        return false;
      }
    },    
    getPlatformPermissions() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/platform/permissions')
      .then(res => {      
        this.platformPermissions = res.data.data;
        // Update the platformPermissionsLoaded value
        this.platformPermissionsLoaded = true;
        // Get available email templates if user has permission
        if(this.platformPermissions.includes('core.emailtemplates') === true) this.getEmailTemplates();
      })
      .catch(err => {
        console.error(err); 
      });
    },
    checkPermission(permissionTag) {
      if(this.platformPermissions.includes(permissionTag)) {
        return true;
      } else{
        return false;
      }
    },
    checkModules() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/platform/modules')
      .then(res => {      
        this.modules = res.data.data;    
      })
      .catch(err => {
        console.error(err); 
      });
    },
    countdown() {
      // Calculate remaining points title length
      if(this.moment.points_title !== null) this.moment.remaining_points_title_length = this.moment.max_points_title_length - this.moment.points_title.length;
      // Calculate remaining points description length
      if(this.moment.points_description !== null) this.moment.remaining_points_description_length = this.moment.max_points_description_length - this.moment.points_description.length;
      // Calculate remaining push title length
      if(this.moment.push_title !== null) this.moment.remaining_push_title_length = this.moment.max_push_title_length - this.moment.push_title.length;
      // Calculate remaining push message length
      if(this.moment.push_messagemessage !== null) this.moment.remaining_push_message_length = this.moment.max_push_message_length - this.moment.push_message.length;
    }    
  },
  mounted: function(){
    this.apiBaseUrl = process.env.VUE_APP_API_URL;
    this.clientToken = localStorage.getItem('token');

    this.checkModules();
    this.getPlatformRoles();
    this.getPlatformPermissions();
  
    this.$bus.$on('email_template_added', (e) => {
      this.getEmailTemplates();
    });

    this.$bus.$on('event_points_updated', (e) => {
      this.newMomentPoints = e.new_event_points;
    });    
  },
  beforeDestroy() {
    this.$bus.$off('email_template_added');
    this.$bus.$off('event_points_updated');
  }
}
</script>
